<template>
  <div class="page-regist traffic">
    <p class="title-box">交通方式</p>
    <div class="page-form">
      <van-radio-group v-model="traffic" @change="change">
        <div class="group-box">
          <van-radio checked-color="#07c160" name="1">自驾</van-radio>
          <van-field v-show="traffic == 1" v-model="carInfo" placeholder="请输入车牌号" maxlength="10" :readonly="readonly"/>
        </div>
        <div class="group-box">
          <van-radio checked-color="#07c160" name="4">大巴</van-radio>
          <van-field v-show="traffic == 4" v-model="carInfo" placeholder="请输入车牌号" maxlength="10" :readonly="readonly"/>
        </div>
        <div class="group-box">
          <van-radio checked-color="#07c160" name="5">网约车</van-radio>
          <van-field v-show="traffic == 5" v-model="carInfo" placeholder="请输入车牌号" maxlength="10" :readonly="readonly"/>
        </div>
        <div class="group-box">
            <van-radio checked-color="#07c160" name="2">火车</van-radio>
            <van-field v-show="traffic == 2" v-model="carInfo" placeholder="请输入车次信息" maxlength="10" :readonly="readonly"/>
        </div>
        <div class="group-box">
          <van-radio checked-color="#07c160" name="3">飞机</van-radio>
          <van-field v-show="traffic == 3" v-model="carInfo" placeholder="请输入航班信息" maxlength="10" :readonly="readonly"/>
        </div>
        <div class="group-box">
          <van-radio checked-color="#07c160" name="0">其他</van-radio>
          <van-field v-show="traffic == 0" v-model="carInfo" placeholder="请输入其他信息" maxlength="10" :readonly="readonly"/>
        </div>
      </van-radio-group>
      <!--<van-field label="车辆信息" v-show="readonly" v-model="carInfo" placeholder="请输入车辆信息" maxlength="10" :readonly="readonly"/>-->
      <!--<van-field label="车牌信息" v-show="traffic == 1 || traffic == 4 || traffic == 5" v-model="carInfo" placeholder="请输入车牌号" maxlength="10" :readonly="readonly"/>-->
    </div>
    <div class="page-buttons">
      <van-button type="primary" @click="doSave" :loading="isSubmiting" :disabled="isSubmiting" round block>下一步
      </van-button>
    </div>
  </div>
</template>

<script>
  import {getUrlParam} from "../../libs/kit";
  import {PUBLIC} from '@/libs/const'
  import {Icon, NavBar, DateField, SexField, PickerField} from '_c';
  import {Field, Uploader, SwitchCell, Panel, Cell, CellGroup, Button, Checkbox} from 'vant';
  import {RadioGroup, Radio} from 'vant';
  import config from '@/config';

export default{
  name: 'Regist',
  components: {
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
    [DateField.name]: DateField,
    [SexField.name]: SexField,
    [PickerField.name]: PickerField,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [SwitchCell.name]: SwitchCell,
    [Panel.name]: Panel,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [config.name]: config,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  data(){
    return {
        consts: PUBLIC,
        isSubmiting: false,
        guardians: [],
        traffic:6 ,
        carInfo: '',
        orderId: '',
        userId: '',
        readonly:true
    }
  },
  created(){
      let openId = this.$store.state.openId;
      this.$post('/tourist/getUserId', {openId}).then(res => {
          this.userId = res.object;
      })
  },
  computed: {
      scenicId(){
          return this.$store.state.scenicId;
      },
      openId(){
          return this.$store.state.openId;
      },
      token(){
          return this.$store.state.token;
      }
  },
  methods: {
    //选中触发
    change() {
      this.readonly = false;
      this.carInfo=""
    },
    //获取用户信息
    doSave() {
      if (this.traffic == 6) {
        this.$toast('请选择交通方式');
        return;
      } else {
          this.$router.push({path: '/tourist',query:{traffic:this.traffic,carInfo:this.carInfo}});
      }
    }
  }
}
</script>

<style lang='scss' scoped>
    .page-regist {
        /*background-color: #fff;*/
        min-height: 100vh;

    }
.page-form{
  margin:4vw 4vw 0;
  border-radius: 8px;
  padding: 0 ;
  background-color: #fff;
    line-height: 2;

    .van-radio{
        span{
            font-size: 3.6vw;
        }
    }
}
.van-cell-group{
  background-color: transparent;
  overflow: hidden;
  border-radius: 8px;
}
.page-guardian-add-btn-small{
  color: #1989fa;
  float: right;
  &::after{
    content: ' ';
    clear: both;
  }
}
.page-guardian-add-btn-big{
  width: 100%!important;
  height: 150px!important;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px dashed #ebedf0;
  border-radius: 4px;
}
.page-guardian-item{
  .van-cell.van-panel__header{
    padding: 0;
  }
}
.page-guardian-item-gap{
  background-color:#f7f8fa;
  width:100vw;
  height:5px;
}
.page-guardian-btn-wrapper{
  padding: 8px;
}
.page-guardian-btn-wrapper::after{
  content: '';
}
.page-buttons{
  padding: 10vw 0 0;
  margin: 0 4vw;
}
</style>
<style lang="scss">
.page-idcard-photo,.page-household-register-photo{
  display: flex;
  padding-top: 8px;
  margin-top: -8px;
  .van-uploader__preview-image,.van-uploader__upload{
    width: 100%!important;
    height: 150px!important;
    margin: 0;
  }
  .van-uploader__input{
    height: 100%;
    width: 100%;
  }
}
.page-idcard-photo{
  .van-uploader{
    width: 100vw;
  }
}
.page-household-register-photo{
  .van-uploader{
    width: 48vw;
  }
}
  .aaa img{
    width: 5vw;
  }
  .group-box {
    display: flex;
    align-content: center;
    border-bottom: 1px solid #ebedf0;
    height: 16vw;
    margin-left: 16px;
    .van-radio {
      width: 30vw;
    }
  }
  .traffic .van-cell {
    padding: 0;
  }
.traffic .van-cell__value{
  display: flex;
}
</style>
